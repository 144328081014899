@import "@fontsource/inter";
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*
==========================
========================== SCROLLBAR
==========================
*/
* {
  scrollbar-width: thin;
  scrollbar-color: var(--neutral) transparent;
  scrollbar-gutter: stable;
}

*::-webkit-scrollbar {
  width: 8px;  /* matches 'thin' in Firefox */
}

*::-webkit-scrollbar-thumb {
  background-color: "red";
  border-radius: var(--rounded);
}

/*
==========================
========================== INPUTS
==========================
*/
input:focus,
textarea:focus,
select:focus {
  outline: none !important;
  --tw-ring-offset-width: 0px !important;
  --tw-ring-offset-color: transparent !important;
  --tw-ring-color: transparent !important;
  box-shadow: none !important;
}

select option:hover,
select option:focus,
select option:active,
select option:checked {
  @apply bg-neutral !important;
}

/* Add neutral background on hover for selects */
select:hover {
  @apply bg-neutral;
}