/* TODO: Move this file to correct place, pages folder is not good for css files */
.react-datepicker {
    @apply bg-base-100 border border-base-300 rounded-lg !important;
  }
  
  .react-datepicker__header {
    @apply bg-base-200 border-base-300 !important;
  }
  
  .react-datepicker__current-month,
  .react-datepicker__day-name,
  .react-datepicker__day {
    @apply text-base-content !important;
  }
  
  .react-datepicker__day:hover {
    @apply bg-primary text-primary-content rounded !important;
  }
  
  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected {
    @apply bg-primary text-primary-content !important;
  }
  
  .react-datepicker__day--disabled {
    @apply text-base-300 !important;
  }
  
  .react-datepicker__navigation-icon::before {
    @apply border-base-content !important;
  }
  
  .react-datepicker__day--in-range {
    @apply bg-primary text-primary-content !important;
  }
  
  .react-datepicker__day--in-selecting-range {
    @apply bg-primary/50 !important;
  }